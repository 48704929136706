import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const NewPlantao = loadable(() => import('@screens/escala_medico/NewPlantao'));

function Index(props) {
  return (
    <Layout {...props}>
      <NewPlantao {...props} />
    </Layout>
  );
}

export default Index;
